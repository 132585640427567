import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getBackLink, getHeaderTitles, getNextQuizLink, getNextTitle } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage, mySubpage }) => {
  const questionNumber = 1
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"THREE"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={
          "What part of the brain is activated when someone is triggered by a stressful situation?"
        }
        answers={["Amygdala", "Brain stem", "Prefrontal cortex", "Hippocampus"]}
        correctAnswerNumber={0}
        answerDetail={
          "The amygdala sits right between our brain stem and our prefrontal cortex. It has one job: to be on the lookout for threats or stressors. Think about it like a smoke detector. It looks for danger and reacts to it."
        }
        nextLink={nextLink}
      ></Quiz>
    </Layout>
  )
}

export default withProgress(Page, 3, 5)
